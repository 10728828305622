const env = process.env.VUE_APP_ENV || "dev";

export const allUrl = {
    dev:{
        BaseUrl:'http://192.168.1.108:5007/bus-pro-service',  //数据请求 ip 地址
        Base_Url:'http://192.168.1.108:5007',  //数据请求 ip 地址
        ReUrl:'http://192.168.1.108:5007/studyapp-service',
        DictUrl: 'http://192.168.1.108:5007/auth-config-service', // 数据字典数据获取 ip
        AudioUrl:'https://192.168.1.239:5544',//音频回显
        AudioUrlPublic: 'https://192.168.1.239:5544',
        ImgUrl: 'https://192.168.1.239:5544/Images/',//图片回显
        UploadAudio: 'https://192.168.1.239:5004/postFile/?dir=/record', // 测试
    },
    // dev:{
    //     // 西北地址
    //     // BaseUrl:'http://front.gateway.nwatmb.cn/bus-pro-service',  //数据请求 ip 地址
    //     BaseUrl:'http://127.0.0.1:5007/bus-pro-service',  //数据请求 ip 地址
    //     // ReUrl:'http://front.gateway.nwatmb.cn/studyapp-service',
    //     ReUrl:'http://127.0.0.1:5007/studyapp-service',
    //     // DictUrl: 'http://front.gateway.nwatmb.cn/auth-config-service', // 数据字典数据获取 ip
    //     DictUrl: 'http://127.0.0.1:5007/auth-config-service', // 数据字典数据获取 ip
    //     UpdateUrl:"http://uploadfiles.nwatmb.cn",
    //     // AudioUrl:'rtmp://vod.study.nwatmb.cn/vod_platform/',
    //     AudioUrl:'http://192.168.1.220:9026',//音频回显 
    //     // AudioUrlPublic: 'http://resource.nwatmb.cn/',
    //     AudioUrlPublic: 'http://192.168.1.220:9026',
    //     // ImgUrl: 'http://resource.nwatmb.cn/course/Images/',
    //     ImgUrl: 'http://192.168.1.220:9026/Images/',//图片回显
    //     // UploadAudio: 'http://uploadfiles.nwatmb.cn/postFile/?dir=record', // 测试
    //     UploadAudio: 'http://192.168.1.220:5004/postFile/?dir=/record', // 测试
    //
    // },
    test:{
        // test
        // BaseUrl:'http://front.gateway.nwatmb.cn/bus-pro-service',  //数据请求 ip 地址
        BaseUrl:'http://192.168.1.220:5007/bus-pro-service',  //数据请求 ip 地址
        Base_Url:'http://192.168.1.220:5007',  //数据请求 ip 地址
        // ReUrl:'http://front.gateway.nwatmb.cn/studyapp-service',
        ReUrl:'http://192.168.1.220:5007/studyapp-service',
        // DictUrl: 'http://front.gateway.nwatmb.cn/auth-config-service', // 数据字典数据获取 ip
        DictUrl: 'http://192.168.1.220:5007/auth-config-service', // 数据字典数据获取 ip
        AudioUrl:'http://192.168.1.220:9026',//音频回显
        // AudioUrlPublic: 'http://resource.nwatmb.cn/',
        AudioUrlPublic: 'http://192.168.1.220:9026',
        // ImgUrl: 'http://resource.nwatmb.cn/course/Images/',
        ImgUrl: 'http://192.168.1.220:9026/Images/',//图片回显
        UploadAudio: 'http://192.168.1.220:10004/postFile/?dir=/record', // 测试
    },
    pre:{
        //预发布
    //     BaseUrl:'https://front.gateway.nwatmb.icao.caetp.cn/bus-pro-service',  //数据请求 ip 地址
    //    Base_Url:'https://front.gateway.nwatmb.icao.caetp.cn',  //数据请求 ip 地址
    //    ReUrl:'https://front.gateway.nwatmb.icao.caetp.cn/studyapp-service',
    //     DictUrl: 'https://front.gateway.nwatmb.icao.caetp.cn/auth-config-service', // 数据字典数据获取 ip
    //    AudioUrl:'https://resource.nwatmb.icao.caetp.cn',//音频回显
    //     AudioUrlPublic: 'https://resource.nwatmb.icao.caetp.cn',
    //    ImgUrl: 'https://resource.nwatmb.icao.caetp.cn/Images/',//图片回显
    //     UploadAudio: 'https://uploadfiles.nwatmb.icao.caetp.cn/postFile?dir=record', // 测试

        //西南/首都机场
        // BaseUrl:'https://front.gateway.icao.caetp.cn/bus-pro-service',  //数据请求 ip 地址
        // Base_Url:'https://front.gateway.icao.caetp.cn',  //数据请求 ip 地址
        // ReUrl:'https://front.gateway.icao.caetp.cn/studyapp-service',
        // DictUrl: 'https://front.gateway.icao.caetp.cn/auth-config-service', // 数据字典数据获取 ip
        // AudioUrl:'https://resource.icao.caetp.cn',//音频回显
        // AudioUrlPublic: 'https://resource.icao.caetp.cn',
        // ImgUrl: 'https://resource.icao.caetp.cn/Images/',//图片回显
        // UploadAudio: 'https://uploadfiles.icao.caetp.cn/postFile/?dir=/record', // 测试

        //中南
        // BaseUrl:'https://front.gateway.csatmb.com/bus-pro-service',  //数据请求 ip 地址
        // Base_Url:'https://front.gateway.csatmb.com',  //数据请求 ip 地址
        // ReUrl:'https://front.gateway.csatmb.com/studyapp-service',
        // DictUrl: 'https://front.gateway.csatmb.com/auth-config-service', // 数据字典数据获取 ip
        // AudioUrl:'https://resource.csatmb.com',//音频回显
        // AudioUrlPublic: 'https://resource.csatmb.com',
        // ImgUrl: 'https://resource.csatmb.com/Images/',//图片回显
        // UploadAudio: 'https://uploadfiles.csatmb.com/postFile/?dir=/record', // 测试

        //北京空管局
        // BaseUrl:'https://front.gateway.icao.caetn.cn/bus-pro-service',  //数据请求 ip 地址
        // Base_Url:'https://front.gateway.icao.caetn.cn',  //数据请求 ip 地址
        // ReUrl:'https://front.gateway.icao.caetn.cn/studyapp-service',
        // DictUrl: 'https://front.gateway.icao.caetn.cn/auth-config-service', // 数据字典数据获取 ip
        // AudioUrl:'https://resource.icao.caetn.cn',//音频回显
        // AudioUrlPublic: 'https://resource.icao.caetn.cn',
        // ImgUrl: 'https://resource.icao.caetn.cn/Images/',//图片回显
        // UploadAudio: 'https://uploadfiles.icao.caetn.cn/postFile/?dir=/record', // 测试

        //西北
        // BaseUrl:'https://front.gateway.nwatmb.cn/bus-pro-service',  //数据请求 ip 地址
        // Base_Url:'https://front.gateway.nwatmb.cn',  //数据请求 ip 地址
        // ReUrl:'https://front.gateway.nwatmb.cn/studyapp-service',
        // DictUrl: 'https://front.gateway.nwatmb.cn/auth-config-service', // 数据字典数据获取 ip
        // AudioUrl:'https://resource.nwatmb.cn',//音频回显
        // AudioUrlPublic: 'https://resource.nwatmb.cn',
        // ImgUrl: 'https://resource.nwatmb.cn/Images/',//图片回显
        // UploadAudio: 'https://uploadfiles.nwatmb.cn/postFile/?dir=/record', // 测试

        //三亚
         BaseUrl:'https://front.gateway.icao.syatmb.com/bus-pro-service',  //数据请求 ip 地址
        Base_Url:'https://front.gateway.icao.syatmb.com',  //数据请求 ip 地址
         ReUrl:'https://front.gateway.icao.syatmb.com/studyapp-service',
         DictUrl: 'https://front.gateway.icao.syatmb.com/auth-config-service', // 数据字典数据获取 ip
         AudioUrl:'https://resource.icao.syatmb.com',//音频回显
        AudioUrlPublic: 'https://resource.icao.syatmb.com',
         ImgUrl: 'https://resource.icao.syatmb.com/Images/',//图片回显
         UploadAudio: 'https://uploadfiles.icao.syatmb.com/postFile/?dir=/record', // 测试

        

    }

}

export const  Url  = allUrl[env];
