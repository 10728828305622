<template>
  <div class="di main-wrap" >
    <!-- 这里设置了ref属性后，在vue组件中，就可以用this.$refs.audio来访问该dom元素 -->
    <audio ref="audio" class="dn" 
    :src="url" 
    :autoplay="config.autoplay"
    :preload="audio.preload"
    @play="onPlay" 
    @error="onError"
    @waiting="onWaiting"
    @pause="onPause" 
    @timeupdate="onTimeupdate" 
    @loadedmetadata="onLoadedmetadata"
    ></audio>
    <div class="mp3PlayerConrent">
      <div class="clickPlay"  v-show="!audio.playing && config.theSpeeds.includes(1)" alt=""  @click="startPlayOrPause_"></div>
      <div class="clickPlay-playing"    v-show="audio.playing && config.theSpeeds.includes(1)" alt=""  @click="startPlayOrPause_"></div>
      <el-slider  v-show=" config.theSpeeds.includes(2)" v-model="sliderTime" :format-tooltip="formatProcessToolTip" @change="changeCurrentTime_" class="slider flexItem"></el-slider>
      
      <span  class="flexItem"  v-show=" config.theSpeeds.includes(3)">{{ audio.maxTime | formatSecond }}</span>
      <img src="~@/assets/replay.png" class="repeat"  v-show=" config.theSpeeds.includes(4)" style="cursor:pointer;" alt="" @click="RefreshChangeCurrentTime_" >
      <img src="~@/assets/pause.png"  v-show=" config.theSpeeds.includes(5) && audio.playing" style="cursor:pointer;" alt="" @click="RefreshChangeCurrentTime_" >
      <img src="~@/assets/play_bai.png"  v-show=" config.theSpeeds.includes(5) && !audio.playing" style="cursor:pointer;" alt="" @click="RefreshChangeCurrentTime_" >
    </div>
  </div>

</template> 

<script>
  function realFormatSecond(second) {
    var secondType = typeof second
    if (secondType === 'number' || secondType === 'string') {
      second = parseInt(second)
      var hours = Math.floor(second / 3600)
      second = second - hours * 3600
      var mimute = Math.floor(second / 60)
      second = second - mimute * 60
      return ('0' + mimute).slice(-2) + ':' + ('0' + second).slice(-2)
    } else {
      return '00:00'
    }
  }
  export default {
    props: {
      config:{
        type:Object,
        default:()=>{
          return{
            autoplay:true,
            theSpeeds:[1,2,3,4],
              
             // url:'http://192.168.1.214:7010/course/mp3/8593d765-d4d3-440d-b244-bcfcdc2117f1.mp3',
            // url:'http://resource.nwatmb.cn/7868DC019B5940A1B9B550E72D41BBF7.mp4',
          }
        }
      },
      theUrl: {
        type: String,
        default: ''
      },
      theSpeeds: {
        type: Array,
        default () {
          return [1, 1.5, 2]
        }
      },
    },
    name: 'VueAudio',
    data() {
      return {
        audio: {
          currentTime: 0,
          maxTime: 0,
          playing: this.config.autoplay,
          muted: false,
          speed: 1,
          waiting: true,
          preload: 'auto'
        },
        sliderTime: 0,
        url:this.$store.state.common.AudioUrl+this.theUrl,
        // url:"http://192.168.1.214:7010/course/mp3/8593d765-d4d3-440d-b244-bcfcdc2117f1.mp3",
        controlList: {
          // 不显示下载
          noDownload: false,
          // 不显示静音
          noMuted: false,
          // 不显示音量条
          noVolume: false,
          // 不显示进度条
          noProcess: false,
          // 只能播放一个
          onlyOnePlaying: false,
          // 不要快进按钮
          noSpeed: false
        },
        playLength:0,//音频总共时长
      }
    },
    watch:{
      theUrl:{
        handler:function(v){
          if(v){
            this.url = this.$store.state.common.AudioUrl + v
          }
        },
        immediate:true
      },
      'config.autoplay':{
        handler:function(v){
          console.log(v);
          if(v){
            this.startPlayOrPause()
          }
          // if(v){
          //   this.url = this.$store.state.common.AudioUrl + v
          // }
        },
        immediate:true
      },
      'audio.playing':{
        handler:function(v){
          this.$emit( "changeAudioState",v)
        },
        immediate:true
      },
    },
    mounted(){
      this.audioEnded()
    },
    methods: {
      startPlayOrPause_(){
        if(this.Pattern!='测试模式'){
          this.startPlayOrPause()
        }
      },
      changeCurrentTime_(index){
        if(this.Pattern!='测试模式'){
          this.changeCurrentTime(index)
        }
      },
      RefreshChangeCurrentTime_(){
        if(this.Pattern!='测试模式'){
          this.RefreshChangeCurrentTime()
        }
      },
      // 进度条toolTip
      formatProcessToolTip(index = 0) {
        index = parseInt(this.audio.maxTime / 100 * index)
        return '进度条: ' + realFormatSecond(index)
      },
      // 播放跳转
      changeCurrentTime(index) {
        console.log(index);
        this.$refs.audio.currentTime = parseInt(index / 100 * this.audio.maxTime)
      },
      //重播
      RefreshChangeCurrentTime(){
        this.changeCurrentTime(0)
        this.startPlay()
      },
      startPlayOrPause() {
        console.log('切换播放状态');
        return this.audio.playing ? this.pausePlay() : this.startPlay()
      },
      // 播放完成
      audioEnded(){
        this.$refs.audio.addEventListener('ended',  () => {  
            this.$emit("audioEndedOver",true)
        }, false);
      },
      // 开始播放
      startPlay() {
        console.log("播放音频路径：" + this.url);
        // 先暂停所有音频播放
        let audios = document.getElementsByTagName('audio');
        [...audios].forEach((item) => {
            item.pause()
        })
        this.$refs.audio && this.$refs.audio.play()
        this.audio.playing = true
      },
      // 获取播放状态
      getPlaystate() {
        return this.audio.playing 
      },
      // 获取当前音频长度
      getPlayLength() {
        return this.audio.playing 
      },
      // 暂停
      pausePlay() {
        this.$refs.audio &&  this.$refs.audio.pause()
        this.audio.playing = false
      },
      // 当音频暂停
      onPause () {
        this.audio.playing = false
      },
      // 当发生错误, 就出现loading状态
      onError () {
        this.audio.waiting = true
      },
      // 当音频开始等待
      onWaiting (res) {
        // console.log(res)
      },
      // 当音频开始播放
      onPlay (res) {
        // console.log(res)
        this.audio.playing = true
        this.audio.loading = false
        if(!this.controlList.onlyOnePlaying){
          return 
        }
        let target = res.target
        let audios = document.getElementsByTagName('audio');
        [...audios].forEach((item) => {
          if(item !== target){
            item.pause()
          }
        })
      },
      // 当timeupdate事件大概每秒一次，用来更新音频流的当前播放时间
      onTimeupdate(res) {
        this.audio.currentTime = res.target.currentTime
        this.sliderTime = parseInt(this.audio.currentTime / this.audio.maxTime * 100)
      },
      // 当加载语音流元数据完成后，会触发该事件的回调函数
      // 语音元数据主要是语音的长度之类的数据
      onLoadedmetadata(res) {
        this.audio.waiting = false
        this.playLength = Math.ceil(res.target.duration)
        this.audio.maxTime = parseInt(res.target.duration)
      }
    },
    filters: {
      formatSecond(second = 0) {
        return realFormatSecond(second)
      },
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
  .main-wrap{
    padding: 0px 23px 0 28px;
  }
  .mp3PlayerConrent{
    // width: 250px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // height: 30px;

  }
  .slider {
    display: inline-block;
    width: 319px;
    margin: 0px 15px;
  }
  .repeat{
    margin-left: 15px;
    width: 14px;
    height: 14px;
  }
  .clickPlay{
    width: 19px;
    height: 17px;
    background: url("../assets/biglaba.png");
    background-size: 100% 100%;
    cursor: pointer;
    &:hover{
      background: url("../assets/_biglaba.png");
      background-size: 100% 100%;

    }
  }
  .clickPlay-playing{
    width: 19px;
    height: 17px;
    background: url("../assets/_biglaba.png");
    cursor: pointer;
    background-size: 100% 100%;
  }
  .di {
    display: inline-block;
  }
  .download {
    color: #409EFF;
    margin-left: 15px;
  }
  .dn{
    // display: none;
  }
</style>