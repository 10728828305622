<template>
    <div id="app">
        <router-view/>
    </div>
</template>
<script>
import {Url} from '@/util'
export default {
    mounted() {
        //删除键不可用
        // window.onkeydown = function(){
        //     let d = window.event.keyCode;
        //     if(d==8){
        //         return false;
        //     }
        // }
        // window.onkeypress = function(){
        //     let d = window.event.keyCode;
        //     if(d==8){
        //         return false;
        //     }
        // }
        this.getSystemInfo()
    },
    methods: {
        async getSystemInfo() {
        let res = await this.AjaxApi.queryConfig()
        if(res.code == '200') {
                document.title = res.data.systemName
                this.$store.commit('setitem',{'name':'queryConfig','item':res.data});
                sessionStorage.setItem("queryConfig",JSON.stringify(res.data))
                sessionStorage.setItem("iconUrl", Url.AudioUrlPublic +"/" +  JSON.parse(res.data.logoData)[0] )
            }
        }
    }
}
</script>   
<style>
@import './css/common.css';
</style>
<style lang="less">
@import url('./css/style.less');
</style>
