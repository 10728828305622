<template>
    <div  @click.stop='Stop()'>
        <div class="Aside" >
            
            <div class="Menu_list">
                <!-- <span class="menu_one"  v-if="menushow&&Pattern!='测试模式'" @click.stop='checklist'></span> -->
            </div>
            <div class="SeeMenu"   @click.stop='checklist' >
                <img src="../assets/SeeMenu.png" alt="" >
            </div>
            <div class="reight_box">
                <div class="Profile">
                    <img class="header_img" :src="headUrl" alt="" @error='error'>
                    <span class="menu_two"  v-if="begin" @click='Check()'></span>
                    
                </div>
                <div class="reight_three">
                    <div class="Three_one" v-if="begin">
                        <div v-for="(item,index) in threeList_" :key="index" @click="checkThree(item)" :class="item.title">
                            <el-tooltip class="item" effect="dark" :content="item.name" placement="left">
                                <span v-if="$store.state.common.threeActive == item.title"> <img :src="item.activePng" alt=""> </span>
                                <span class="unActive" v-else></span>
                            </el-tooltip>
                
                        </div>
                    </div>
                </div>
            </div>   
            <div class="logo">
                <img :src="imgUrl" alt="">
            </div>
        </div>
        <div :class="['Nav_list',navlistshow?'':'active']"  @click.stop='Stop()' 
            element-loading-text="Searching"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            v-loading="loadingTree">
            <div class="head_search">
                <div class="t1">
                    <img src="../assets/图钉.png" class="m1" alt="">
                    <i>CATALOGUE</i>
                    <img :src="imgUrl" alt="" style="height:70px;">
                    <img src="../assets/M_BACK.png" class="m2" alt="" @click='navlistshow=false'>
                </div>
                <div class="t2">
                    <img src="../assets/shous.png" alt="" class="sousuo" @click="search()">
                    <input type="text" v-model="searchmsg" placeholder="search" class="t2" @keydown.enter="search()">
                    <img src="../assets/Close.png" alt="" v-show="searchmsg!=''" class="qingchu" @click="cleanSearch()">
                </div>
            </div>
            <tree :Menulist.sync='navlist' :checkid='checkid' :outerId='outerId' :layerlist='layerlist' @click.stop='Stop()'></tree>
        </div>
    </div>
</template>

<script>
    const deHeadUrl  = require("../assets/tou.jpeg")
    import tree from './tree'
    import { Url } from '@/util'
    export default {
        data(){
            return {
                outTime: 8,
                searchmsg:'',
                navlist:[],
                navlistshow:false,
                defaultProps: {
                    children: 'childList',
                    label: 'catalog'
                },
                keys:[],
                tid:'',
                layerlist:[],
                ReTime:null,
                heartbeat:null,
                heartbeatshu:0,
                menushow:false,
                loadingTree:false,
                imgList:[
                    {
                        title:'TEXT',
                        base:require('../assets/textUnClick.png'),
                        active:require('../assets/textClick.png'),
                    },
                    {
                        title:'NOTES',
                        base:require('../assets/noteUnClick.png'),
                        active:require('../assets/noteClick.png'),
                    },
                    {
                        title:'KEY',
                        base:require('../assets/answerUnClick.png'),
                        active:require('../assets/answerClick.png'),
                    },
                ],
            }
        },
        components:{
            tree
        },
        created() {
            if(!!this.ReTime) clearInterval(this.ReTime);
            if(!!this.heartbeat) clearInterval(this.heartbeat);
            let RemainingTime = sessionStorage.getItem('alltime');
            if(!!RemainingTime){
                this.$store.commit('setitem',{'name':'RemainingTime','item':parseFloat(RemainingTime)});
            }
            let begin = sessionStorage.getItem('begin');
            if(begin=='true'){
                this.menushow = true;
            }
            // let usertype = sessionStorage.getItem('usertype');
            let usertype = 3 // 2020-09-21
            this.$store.commit('setitem',{'name':'usertype','item':usertype})

            this.getinfo();
            let complete = sessionStorage.getItem('checkcomplete');
            if(complete=='complete'){
                this.$store.commit('setitem',{'name':'CheckShow','item':false});
            }
            let id = sessionStorage.getItem('curriculum');
            if(!!id&&id!='') this.$store.commit('setitem',{'name':'curriculum','item':id})
        },
        computed:{
            begin:function(){
                return this.$store.state.common.begin;
            },
            checkid:function(){
                return this.$store.state.common.checkid;
            },
            outerId:function(){
                return this.$store.state.common.outerId;
            },
            MenuSwitch:function(){
                return this.$store.state.common.MenuSwitch;
            },
            WithdrawalStudy:function(){
                return this.$store.state.common.WithdrawalStudy;
            },
            Timeabc:function(){
                return this.$store.state.common.RemainingTime;
            },
            usertype:function(){
                return this.$store.state.common.usertype;
            },
            testPause:function(){
                return this.$store.state.common.testPause;
            },
            continueStudy:function(){
                return this.$store.state.common.continueStudy;
            },
            threeList_:function(){
                // let list = ['TEXT','NOTES','KEY','EMPHASIS','KNOWLEDGESEARCHING','WORDLIST']
                let list = this.$store.state.common.threeList;
                let text = {
                    title:'TEXT',
                    dftPng:require('../assets/dftText.png'),
                    hoverPng:require('../assets/hoverText.png'),
                    activePng:require('../assets/activeText.png'),
                    name:"Text"
                    // base:require('../assets/textUnClick.png'),
                    // active:require('../assets/textClick.png'),
                }
                let note = {
                    title:'NOTES',
                    dftPng:require('../assets/dftNotes.png'),
                    hoverPng:require('../assets/hoverNotes.png'),
                    activePng:require('../assets/activeNotes.png'),
                     name:"Notes"
                }
                let key = {
                    title:'KEY',
                    dftPng:require('../assets/dftKey.png'),
                    hoverPng:require('../assets/hoverKey.png'),
                    activePng:require('../assets/activeKey.png'),
                     name:"Key"
                }
                let emphasis = {
                    title:'EMPHASIS',
                    dftPng:require('../assets/dftEmphasis.png'),
                    hoverPng:require('../assets/hoverEmphasis.png'),
                    activePng:require('../assets/activeEmphasis.png'),
                     name:"Emphasis"
                }
                let knowledgeSearching = {
                    title:'KNOWLEDGESEARCHING',
                    dftPng:require('../assets/dftKnowledgeSearching.png'),
                    hoverPng:require('../assets/hoverKnowledgeSearching.png'),
                    activePng:require('../assets/activeKnowledgeSearching.png'),
                    name:"Knowledge search"
                }
                let wordList = {
                    title:'WORDLIST',
                    dftPng:require('../assets/dftWordList.png'),
                    hoverPng:require('../assets/hoverWordList.png'),
                    activePng:require('../assets/activeWordList.png'),
                    name:"Word list"
                }
                let list_ = [];
                for (let i = 0; i < list.length; i++) {
                    let le = list[i];
                    if(le=='TEXT'){
                        list_.push(text);
                    }else if(le=='NOTES'){
                        list_.push(note);
                    }else if(le=='KEY'){
                        list_.push(key);
                    }

                    else if(le=='EMPHASIS'){
                        list_.push(emphasis);
                    }else if(le=='KNOWLEDGESEARCHING'){
                        list_.push(knowledgeSearching);
                    }else if(le=='WORDLIST'){
                        list_.push(wordList);
                    }
                }
                return list_;
            },
            imgUrl() {
                return Url.AudioUrl + '/' + JSON.parse(this.$store.state.common.queryConfig.logoData)[1]
            },
            headUrl() {
                return this.$store.state.common.headUrl;
            },
        },
        async mounted() {
            this.resetStopTime();
            document.onclick = ()=>{
                this.navlistshow = false;
                this.resetStopTime()
                // let time = new Date();
                // time = time.getTime() / 1000;
                // console.log(time);
                // sessionStorage.setItem('stopStydyTime',time); // 停止学习时间
            }
            this.getOutTime()
        },
        watch:{
            MenuSwitch:{
                handler(){
                    this.navlistshow = false;
                }
            },
            // MenuSwitch:function(){
            //     this.navlistshow = false;
            // },
            checkid:function(v,oldv){
                let layer = sessionStorage.getItem('targetceng').split(',');
                let Alayer = [];
                while (layer.length>0) {
                    let str = layer.join(',');
                    layer.pop();
                    Alayer.push(str)
                }
                console.log('Alayer',Alayer);
                this.layerlist = Alayer;
            },
            heartbeatshu:function(){
                this.Heartdown();
            },
            WithdrawalStudy:function(v){
                if(!!this.heartbeat&&this.heartbeat!=null&&this.heartbeat!=undefined)clearInterval(this.heartbeat);
                if(!!this.ReTime&&this.ReTime!=null&&this.ReTime!=undefined)clearInterval(this.ReTime);
            },
            Timeabc:function(v){
                let c = window.formatDuring(v);
                this.$store.commit('setitem',{'name':'RemaTime','item':c});
            },
            begin:function(v){
                if(!v){
                    this.menushow = false;
                    this.ReSstart();
                }else{
                    this.menushow = true;
                }
            },

            testPause:function(v,oldv){
                if(this.Pattern=='测试模式'){
                    if(v&&!oldv){ // 开始
                        if(!!this.QuestionChange){
                            this.QuestionChange();
                        }else{
                            if(!!this.pauseBegin)this.pauseBegin();
                        }
                    }
                    if(oldv&&!v){ // 暂停
                        if(!!this.stayPause)this.stayPause();
                    }
                }
            },
            continueStudy:function(){
                this.SetTime();
            },
        },
        methods:{
            resetStopTime(){
                let time = new Date();
                time = time.getTime() / 1000;
                sessionStorage.setItem('stopStudyTime',time); // 停止学习时间
            },
            checkThree(item){
                if (this.$store.state.common.searchActive === true || this.$store.state.common.wordsActive === true) {
                    this.$store.commit('setitem',{'name':'searchActive','item':false});
                    this.$store.commit('setitem',{'name':'wordsActive','item':false});
                }
                if (item.title === 'KNOWLEDGESEARCHING'){
                    this.$store.commit('setitem',{'name':'searchActive','item':true});
                    this.$store.commit('setitem',{name:'threeActive',item:''});
                    return
                }
                else if (item.title === 'WORDLIST'){
                    this.$store.commit('setitem',{'name':'wordsActive','item':true});
                    this.$store.commit('setitem',{name:'threeActive',item:''});
                    this.$store.commit('setitem',{'name':'menushow','item':true});
                    this.bus.$emit('updateData', {loading: true});
                    return
                }

                if(this.$store.state.common.threeActive == item.title){
                    this.$store.commit('setitem',{name:'threeActive',item:''});
                }else{
                    this.$store.commit('setitem',{name:'threeActive',item:item.title});
                }
            },
            stayPause(){ // 暂停 心跳
                if(!!this.heartbeat&&this.heartbeat!=null&&this.heartbeat!=undefined)clearInterval(this.heartbeat);
                if(!!this.ReTime&&this.ReTime!=null&&this.ReTime!=undefined)clearInterval(this.ReTime);
            },
            pauseBegin(){ // 暂停 开始
                this.SetTime();
            },
            Stop(){
                console.log(123);
            },
            ReSstart(){
                this.getinfo();
            },
            checklist(){
                // 学习开始允许 查看 课程列表
                if(this.menushow &&　this.Pattern != '测试模式'){
                    // this.searchmsg = ""
                    if(!this.navlistshow ){
                        let temp = JSON.parse(JSON.stringify(this.navlist))
                        this.navlist = temp.map(item => {
                            item.Tshow = false
                            return item
                        })
                    }
                    this.navlistshow = !this.navlistshow;
                }
            },
            error(e){
                this.headUrl = deHeadUrl
                // e.src = "../assets/tou.jpeg";
            },
            getQueryString(name) {
                var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
                var r = window.location.search.substr(1).match(reg);
                if (r != null) {
                    return unescape(r[2]);
                }
                return null;
            },
            async getinfo(){
                let studylog = sessionStorage.getItem('studylog');
                if(!!!studylog){
                    sessionStorage.setItem('studylog','[]');
                }
                let id = this.$route.query.id || sessionStorage.getItem('curriculum');
                let token = this.$route.query.token;
                // let type = this.$route.query.type || '1';
                let type = 3; //永久课时 2020-9-19
                let enterpriseUserId = this.$route.query.enterpriseUserId || '';
                let setmealCourseId = this.$route.query.setmealCourseId || '';
                let ExamType = this.$route.query.ExamType=='second'?2:1;
                let setmealId = this.$route.query.setmealId || '';
                let isJudgment = this.$route.query.isJudgment || '0';
                let trainClassId = this.$route.query.trainClassId || '';
                let ContinueLearning = this.$route.query.continueLearning || '';
                let seatMealCourseId = this.$route.query.setmealCourseId || 'abc';
                let name = this.$route.query.name || 'Test123';
                if(ContinueLearning!='') window.ContinueLearning = ContinueLearning;

                let begin = sessionStorage.getItem('begin');
                let usertype = sessionStorage.getItem('usertype');
                if(begin!='true'&&usertype!=0&&usertype!=1){
                    sessionStorage.setItem('trainClassId',trainClassId);
                    sessionStorage.setItem('seatMealCourseId',seatMealCourseId);
                    sessionStorage.setItem('setmealId',setmealId);
                    sessionStorage.setItem('token',token);
                    sessionStorage.setItem('setmealCourseId',setmealCourseId);
                    sessionStorage.setItem('enterpriseUserId',enterpriseUserId);
                    sessionStorage.setItem('type',type);
                    sessionStorage.setItem('curriculum',id);
                    sessionStorage.setItem('userName',name);
                    sessionStorage.setItem('isJudgment',isJudgment);
                }
                let j = sessionStorage.getItem('isJudgment');
                if(!!j&&j!='') this.$store.commit('setitem',{'name':'isJudgment','item':j});
                this.$store.commit('setitem',{'name':'curriculum','item':id});
                let OldData = sessionStorage.getItem('menulist');
                OldData = JSON.parse(OldData);
                let urlData = null;
                if(!!OldData&&OldData.length>0&&OldData!=null){
                    urlData = OldData;
                }else{
                    if( !id ) return
                    let url = "/course/getOldCouseCatlogById?id=" + id + `&type=${ExamType}`;
                    let url_ = {};
                    try{
                        url_  = await this.$ajax.get(url);
                    }catch(e){
                        this.$message.error('获取数据失败!');
                    }
                    urlData = url_.data;
                }
                let dataStructure = window.MenuLayer(urlData);
                this.$store.commit('setitem',{'name':'dataStructure','item':dataStructure});
                // 判断 模式
                let list = window.Menulist(false,urlData);
                list.forEach(el=>{
                    if(!!el.tbCourse&&el.tbCourse.catalogPatternName == '闯关模式'){
                        sessionStorage.setItem('MaxPattern','闯关模式');
                        this.$store.commit('setitem',{'name':'MaxPattern','item':'闯关模式'});
                    }
                })

                let copylist = window.Copy(list);
                sessionStorage.setItem('menulist',JSON.stringify(copylist));
                this.$store.commit('setmenulist',copylist);  // 存储 菜单 数据
                window.LayerList = [];
                this.navlist = window.Menulist(true,urlData);
                console.log('this.navlist',this.navlist);
                let ceng = sessionStorage.getItem('targetceng');
                if(!!ceng){
                    // 获取 当前 层级 设置 对应样式
                    let data = this.GetTargetItem();
                    let lista = null;
                    if(!!data.last){
                        lista = data.data[data.last];
                    }else{
                        lista = data.data;
                    }
                    this.$store.commit('setitem',{'name':'checkid','item':lista.id});
                    this.$store.commit('setitem',{'name':'outerId','item':data.updata.id});
                    if(begin!='true'&&usertype!=0&&usertype!=1&&window.ContinueLearningCan=='true'){
                        // this.StartStudy();
                    }
                }
                this.$store.commit('setdatacomplate');  // 设置数据获取完成
            },
            cleanSearch (){
                this.searchmsg = "";
                this.search();
            },
            // 搜索 章节目录
            async search(){
                this.loadingTree=true;
                let id =  sessionStorage.getItem('curriculum')
                let url = "/course/getOldCouseCatlogById?id=" + id + `&type=1&searchValue=${this.searchmsg}`;
                let res = {};
                try{
                    res  = await this.$ajax.get(url);
                }catch(e){
                    this.loadingTree=false;
                    this.$message.error('获取数据失败!');
                }
                if(res.code =="200" && res.data.length>0){
                    this.navlist = res.data
                }else{
                    this.$message.warning("未搜索到相关内容!")
                }
                this.loadingTree=false;
                
                

            },

            async SetTime(){
                if(!!this.heartbeat&&this.heartbeat!=null&&this.heartbeat!=undefined)clearInterval(this.heartbeat);
                if(!!this.ReTime&&this.ReTime!=null&&this.ReTime!=undefined)clearInterval(this.ReTime);
                let type = sessionStorage.getItem('usertype');
                this.heartbeat = setInterval(()=>{
                    console.log('heart down');
                    this.heartbeatshu++;
                    clearInterval(this.ReTime);
                },60000)
                this.ReTime = setInterval(()=>{
                    if(this.Timeabc==180&&type!='1'){
                        this.$store.commit('setitem',{name:'threeTimeShow',item:true});

                        // this.$confirm('Only 3 minutes left!', 'Confirm', {
                        //     confirmButtonText: 'Yes,i know',
                        //     type: 'warning',
                        //     showCancelButton:false,
                        //     showClose:false,
                        //     closeOnClickModal:false,
                        // }).then(() => {

                        // });
                    }
                    if(type=='1'){
                        this.$store.commit('setRemainingTime');
                    }else{
                        if(this.Timeabc>0){
                            this.$store.commit('setRemainingTime');
                        }else{
                            if(!!this.heartbeat&&this.heartbeat!=null&&this.heartbeat!=undefined)clearInterval(this.heartbeat);
                            if(!!this.ReTime&&this.ReTime!=null&&this.ReTime!=undefined)clearInterval(this.ReTime);
                            this.Tuichu();
                        }
                    }

                },1000);
            },
            async Tuichu(){
                // let type = sessionStorage.getItem('usertype');
                // let Utype = sessionStorage.getItem('type');
                // let str = '';
                // if(type=='1') str = 3;
                // else str = Utype;
                let str = 3
                let url = "/courseApp/quitLearning";
                let data = {
                    type:str,
                    'courseId':this.$store.state.common.curriculum,
                    'sectionId':sessionStorage.getItem('checkid'),
                    'setmealCourseId':sessionStorage.getItem('setmealCourseId'),
                    'enterpriseUserId':sessionStorage.getItem('enterpriseUserId'),
                }
                let url_ = await this.AjaxApi.OutSide(data);
                this.$store.commit('setitem',{name:'timeOutShow',item:true});
                // this.$confirm('Sorry,this time run out', 'Confirm', {
                //     confirmButtonText: 'Yes',
                //     type: 'warning',
                //     showCancelButton:false,
                //     showClose:false,
                //     closeOnClickModal:false,
                // }).then(function(){
                //     window.close();
                // }).catch(function(){
                //     window.close();
                // });
            },
            async getOutTime(){
                console.log(1);
                let res  = await this.AjaxApi.getOutTime()
                this.outTime = Number.parseInt(res.data)
            },
            async Heartdown(){
                // let type = sessionStorage.getItem('usertype');
                // let Utype = sessionStorage.getItem('type');
                // let str = '';
                // if(type=='1') str = 3;
                // else str = Utype;
                let type = 3
                let str = 3
                let tartgt = this.$store.state.common.TargetItem;
                let AItem = {};
                if(!!tartgt.last){
                    AItem = tartgt.data[tartgt.last];
                }else{
                    AItem = tartgt.data;
                }
                let c = {
                    'setmealCourseId':sessionStorage.getItem('setmealCourseId'),
                    'enterpriseUserId':sessionStorage.getItem('enterpriseUserId'),
                }
                let Str_C = '';
                for (const key in c) {
                    Str_C += '&' + key + '=' + c[key];
                }
                console.log(str,AItem,Str_C,'str,AItem,Str_C');

                // 永久套餐 2020-09-21
                let url_ = {
                    data:1000*86400*366*1000
                }

                sessionStorage.setItem('alltime',url_.data/1000);
                this.$store.commit('setitem',{'name':'RemainingTime','item':parseFloat(url_.data/1000)});
                if(this.Pattern != '测试模式'){
                    let time = new Date();
                    time = time.getTime() / 1000;
                    let oldTime = sessionStorage.getItem('stopStudyTime');
                    oldTime = parseInt(oldTime);
                    let rTime = (time - oldTime) - 60 * this.outTime;
                    if(rTime>0){ // 超出8 分钟
                        sessionStorage.setItem("chapterSuspendTime",(oldTime + 60 * this.outTime) * 1000)
                        await  this.AjaxApi.eightStop(AItem);
                        if(!!this.heartbeat&&this.heartbeat!=null&&this.heartbeat!=undefined)clearInterval(this.heartbeat);
                        if(!!this.ReTime&&this.ReTime!=null&&this.ReTime!=undefined)clearInterval(this.ReTime);
                        this.$store.commit('setitem',{'name':'outStudyShow','item':true});
                        return false;
                    }
                }
                this.ReTime = setInterval(()=>{
                    if(this.Timeabc==180){
                        this.$store.commit('setitem',{name:'threeTimeShow',item:true});
                        // this.$confirm('Only 3 minutes left!', 'Confirm', {
                        //     confirmButtonText: 'Yes,i know',
                        //     type: 'warning',
                        //     showCancelButton:false,
                        //     showClose:false,
                        //     closeOnClickModal:false,
                        // }).then(() => {
                        // });
                    }
                    if(type=='1'){
                        this.$store.commit('setRemainingTime');
                    }else{
                        if(this.Timeabc>0){
                            this.$store.commit('setRemainingTime');
                        }else{
                            if(!!this.heartbeat&&this.heartbeat!=null&&this.heartbeat!=undefined)clearInterval(this.heartbeat);
                            if(!!this.ReTime&&this.ReTime!=null&&this.ReTime!=undefined)clearInterval(this.ReTime);
                            this.Tuichu();
                        }
                    }
                },1000);
            },
            Check(){
                this.$store.commit('setitem',{'name':'Testing','item':true});
                this.$store.commit('setitem',{'name':'CheckShow','item':true});
            },
        },
        updated() {

        },
        beforeDestroy() {
            if(!!this.heartbeat&&this.heartbeat!=null&&this.heartbeat!=undefined)clearInterval(this.heartbeat);
            if(!!this.ReTime&&this.ReTime!=null&&this.ReTime!=undefined)clearInterval(this.ReTime);
        },
    }
</script>
