import {Axios} from '../axios'
import {Axios as upCorderAxios} from '@/utils/axios.js'
import { Message } from 'element-ui'
import {Url as ipUrl} from '../util'

const urlModular1 = "/enterprise-service"

class AjaxApi {
    static async TongYong(url) {
        // 通用  ajax 获取 数据 默认 Get
        // 数据格式  {'url':url,'type':'','txt':txt}
        let type = window.Panduanxx(url);
        if(type!='json'){
            url = {
                'url':url,
            }
        }
        if (!url.type) {
            url.type = 'get';
        }
        if(!url.txt){
            url.txt = '获取信息失败!';
        }
        let c;
        if(url.type=='get'){
            try{
                c = await Axios.get(url.url);
            }catch(e){
                Message.error(url.txt);
                console.log(url.url);
                console.log(e)
                c = false;
            }
        }
        if(url.type=='post'){
            try{
                c = await Axios.post(url.url,url.str);
            }catch(e){
                Message.error(url.txt);
                console.log(url.url)
                c = false;
            }
        }
        if(url.type=='upCorder'){
            try{
                c = await upCorderAxios.post(url.url,url.str);
            }catch(e){
                // Message.error(url.txt);
                console.log(url.url)
                c = false;
            }
        }
        return c;
    }

    static async TongYong2(url) {
        // 通用  ajax 获取 数据 默认 Get
        // 数据格式  {'url':url,'type':'','txt':txt}
        let type = window.Panduanxx(url);
        if(type!='json'){
            url = {
                'url':url,
            }
        }
        if (!url.type) {
            url.type = 'get';
        }
        if(!url.txt){
            url.txt = '获取信息失败!';
        }
        let c;
        if(url.type=='get'){
            try{
                c = await Axios.get(url.url);
            }catch(e){
                //Message.error(url.txt);
                console.log(url.url);
                console.log(e)
                c = false;
            }
        }
        if(url.type=='post'){
            try{
                c = await Axios.post(url.url,url.str);
            }catch(e){
                //Message.error(url.txt);
                console.log(url.url)
                c = false;
            }
        }
        if(url.type=='upCorder'){
            try{
                c = await upCorderAxios.post(url.url,url.str);
            }catch(e){
                //Message.error(url.txt);
                console.log(url.url)
                c = false;
            }
        }
        return c;
    }

    static async upRecord(data){ //录音上传
        console.log(data.get("file"),"录音上传的文件");
        let url =  ipUrl.UploadAudio;
        let log = await this.TongYong({'type':'upCorder','url':url,'str':data});
        if(log){
            return log;
        }else{ // 由于上传响应比较慢，为了提升用户体验，添加默认录音音频
            return { code: "200" , data: "/record/a952ffe5-b263-4d57-8789-4e02a8dec4ba.wav" , message: "上传成功！"}
        }
    }
    static async CheckHeart(){ //心跳检查
        let curriculum = sessionStorage.getItem('curriculum');
        let url = "/courseApp/checkHeartBeat?courseId=" + curriculum;
        let url_ = await this.TongYong(url);
        return url_;
    }
    static async AddStudyLog(data){//学习记录
        let url = "/TbStudyLog/addTbStudyLog";
        let log = await this.TongYong({'type':'post','url':url,'str':data});
        return log;
    }
    static async OutSide(data){//退出学习
        let url = "/courseApp/quitLearning";
        let url_ = await this.TongYong({'url':url,'type':'post','str':data});
        return url_;
    }
    static async GetScore(data){//获取分数
        let urla = '/score/getScore';
        let url_ = await this.TongYong({url:urla,str:data,type:'post'});
        return url_;
    }
    static async StartStudy(){//开始学习
        let type = sessionStorage.getItem('type');
        let url = "/courseApp/startStudy?courseId=" + sessionStorage.getItem('curriculum');
        if(type==0){
            url = "/courseApp/startStudy?courseId=" + sessionStorage.getItem('curriculum') + "&type=" + type;
        }else if(type==1){
            let enterpriseUserId = sessionStorage.getItem('enterpriseUserId');
            let setmealCourseId = sessionStorage.getItem('setmealCourseId');
            let setmealId = sessionStorage.getItem('setmealId');
            url = "/courseApp/startStudy?enterpriseUserId=" + enterpriseUserId + '&setmealCourseId=' + setmealCourseId + "&type=" + type + "&courseId=" + sessionStorage.getItem('curriculum') + "&setmealId=" + setmealId;
        }else{
            this.$message.error('信息错误!');
            return false;
        }
        let url_ = await this.TongYong(url);
        return url_;
    }
    static async getTotalScore(){//各种题型分项等级成绩
        let uuid = sessionStorage.getItem('uuid') || '35294758788818923722285928321500';
        let ti = '/score/getTotalScore?uuid=' + uuid;
        let ti_ = await this.TongYong(ti);
        return ti_;
    }
    static async getAvgScore(){//各个单项等级成绩
        let uuid = sessionStorage.getItem('uuid') || '35294758788818923722285928321500';
        let ti = '/score/getAvgScore?uuid=' + uuid;
        let ti_ = await this.TongYong(ti);
        return ti_;
    }
    static async getIcaoScore(){//ICAO等级成绩
        let uuid = sessionStorage.getItem('uuid') || '35294758788818923722285928321500';
        let ti = '/score/getIcaoScore?uuid=' + uuid;
        let ti_ = await this.TongYong(ti);
        return ti_;
    }
    static async getCourseInfo(id){//获取课程信息
        let url = "/course/getCourseInfo?courseId=" + id;
        let url_ = await this.TongYong(url);
        return url_;
    }
    static async HeartDown(str,AItem,Str_C){// 心跳
        if (AItem == undefined) {
            return null;
        }
        let curriculum = sessionStorage.getItem('curriculum');
        let setmealId = sessionStorage.getItem('setmealId');
        let url = "/courseApp/heartBeat?courseId=" + curriculum + '&type=' + str + '&sectionId=' + AItem.id + Str_C + '&setmealId=' + setmealId;
        let url_ = await this.TongYong(url);
        return url_;
    }
    static async getAnswerById(id){//获取答案
        let url = "/question/getAnswerById?questionId=" + id;
        let key = await this.TongYong(url);
        return key;
    }
    static async addVideo(data){//新增录音记录
        let add = "/TbStudyLog/addVideo";
        if(sessionStorage.getItem('trainClassId'))  data.trainClassId = sessionStorage.getItem('trainClassId')
        let add_ = await this.TongYong({'url':add,'type':'post','str':data});
        return add_;
    }
    static async getNewVideo(id){//录音查询
        let record ="/TbStudyLog/getNewVideo?id=" + id;
        let record_ = await this.TongYong(record);
        return record_;
    }
    static async getNoteByQuestionId(id){//笔记查询
        let seatMealCourseId = sessionStorage.getItem('seatMealCourseId')||'';
        let notes = "/CourseNote/getNoteByQuestionId?id=" + id + '&seatMealCourseId=' + seatMealCourseId;
        if(sessionStorage.getItem('trainClassId'))  notes += '&trainClassId=' + sessionStorage.getItem('trainClassId')
        let notes_ = await this.TongYong(notes);
        return notes_;
    }
    static async updateCourseNote({id,content}){//笔记修改
        let seatMealCourseId = sessionStorage.getItem('seatMealCourseId')||'';
        let edit = '/CourseNote/updateCourseNote?id=' + id + "&content=" + content + '&seatMealCourseId=' + seatMealCourseId;
        let edit_ = await this.TongYong(edit);
        return edit_;
    }
    static async addCourseAdvice(data){//添加笔记
        let seatMealCourseId = sessionStorage.getItem('seatMealCourseId')||'';
        let trainClassId = sessionStorage.getItem('trainClassId') || ''
        data.seatMealCourseId = seatMealCourseId;
        data.trainClassId = trainClassId;
        let add = '/CourseNote/addCourseAdvice';
        let add_ = await this.TongYong({'url':add,'type':'post','str':data});
        return add_;
    }
    static async addAnswerRecord(data){//添加 答案录音记录
        let url = '/AnswerRecord/addAnswerRecord';
        let upData = await this.TongYong({'type':'post','url':url,'str':data});
        return upData;
    }
    static async endTest(data){//测试结束
        let url = '/score/endTest';
        // 2020-09-22
        let trainClassId = sessionStorage.getItem('trainClassId') || ''
        if(trainClassId) {
            url = '/trainClassTest/endTest'
            data.trainClassId = trainClassId
        }
        data.rid = sessionStorage.getItem('rid') || '';
        let upData = await this.TongYong({'type':'post','url':url,'str':data});
        return upData;
    }
    static async eightStop(item){//测试结束
        let curriculum = sessionStorage.getItem('curriculum');
        let url = '/courseApp/changeStudyTime?courseId='+ curriculum + '&sectionId=' + item.id;
        let url_ = await this.TongYong({'type':'get','url':url});
        return url_;
    }
    static async testRequire(courseId){
        let url = '/courseApp/testRequire?courseId=' + sessionStorage.getItem('curriculum');
        // 2020-09-22
        let trainClassId = sessionStorage.getItem('trainClassId') || ''
        if(trainClassId) url = '/trainClassTest/testRequire?courseId=' + sessionStorage.getItem('curriculum') + '&trainClassId=' + trainClassId
        let url_ = await this.TongYong({'type':'get','url':url});
        return url_;
    }
    static async getOutTime(){//学习记录
        let url = ipUrl.DictUrl + "/dict/getStopTimeConfig";
        let log = await this.TongYong({'type':'post','url':url, 'str':{}});
        // debugger
        return log;
    }

    static async getSearch(parame){//搜索
        let url = ipUrl.ReUrl + '/TbKnowledgeSearchStatistics/querySearchListByPCParams' 
        let arr = Object.keys(parame)
        arr.map(el=>{
            if(!parame[el]){
                delete parame[el]
            }
        })
        if(!parame.searchValue) parame.searchValue = ''
       
        console.log('parame',parame);
        let data = await this.TongYong({'url':url,'type':'post','str':parame});
        return data;
    }

    static async getSearch2(courceId, searchValue, trainClassId){//搜索
        let url = ipUrl.ReUrl + '/TbNewWordBook/queryListByPcParams'
        
        let parame = {
            courceId,
            searchValue,
            trainClassId
        }
        let arr = Object.keys(parame)
        arr.map(el=>{
            if(!parame[el]){
                delete parame[el]
            }
        })
        if(!parame.searchValue) parame.searchValue = ''
        let data = await this.TongYong({'url':url,'type':'post','str':parame});
        return data;
    }


    static async setFav(courceId, questionId, sectionId, sectionName, trainClassId){//收藏
        let url = ipUrl.ReUrl + '/TbNewWordBook/save'
            + '?courceId=' + courceId
            + '&questionId=' + questionId
            + '&sectionId=' +  sectionId
            + '&sectionName=' +  sectionName
        if (trainClassId !== null && trainClassId !== '' && trainClassId !== undefined){
            url += '&trainClassId=' + trainClassId
        }
        console.log(url)
        let data = await this.TongYong2({'type':'post','url':url});
        return data;
    }

    static async checkFav(courceId, questionId, sectionId, trainClassId){//是否收藏
        let url = ipUrl.ReUrl + '/TbNewWordBook/checkIsCollection'
            + '?courceId=' + courceId
            + '&questionId=' + questionId
            + '&sectionId=' +  sectionId

        if (trainClassId !== null && trainClassId !== '' && trainClassId !== undefined){
            url += '&trainClassId=' + trainClassId
        }
        console.log(url)
        let data = await this.TongYong2({'type':'post','url':url});
        return data;
    }

    static async deleteData(ids){//删除
        let url = ipUrl.ReUrl + '/TbNewWordBook/delete'
            + '?ids=' + ids
        console.log(url)
        let data = await this.TongYong2({'type':'post','url':url});
        return data;
    }

    static async queryListByCourceId(data){// 热搜前6查询
        let url = ipUrl.ReUrl + "/TbKnowledgeSearchStatistics/queryListByCourceId";
        let log = await this.TongYong({'type':'post','url':url, 'str':data});
        // debugger
        return log;
    }

    static async queryConfig() {
        let url = ipUrl.Base_Url + urlModular1 + "/tbEnterpriseConfig/queryConfig";
        let data = await this.TongYong({'type': 'get', 'url': url})
        return data
    }

}
export default {
    install(Vue) {
        Object.defineProperty(Vue.prototype, 'AjaxApi', {
            value: AjaxApi
        })
    },
}
